import { css } from 'aphrodite';
import styles from './GiveBernyACall.styles';
import { KIFFGO } from '../../../library/kiffgo';
import React from 'react';

const GiveBernyACall = () => (
  <div className={css(styles.root)}>
    <a className={css(styles.phoneNumber)} href="javascript:void(Chatra('openChat', true))">
      <div className={css(styles.bernardImgContainer)}>
        <img className={css(styles.bernardImg)}
          src={require('../../../assets/team/sarah.png')} />
        <div className={css(styles.onlineCircle)} />
      </div>
      <p style={{ margin: 0, fontSize: 12, paddingTop: 16 }}>Chat with Sarah, our customer care assistant, she will do anything to help</p>
    </a>
  </div>

);

export default GiveBernyACall
